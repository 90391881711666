.map__iframe {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  border: none;
  outline: none;
  opacity: 0;
  pointer-events: none;
}

.map__iframe._show {
  opacity: 1;
  pointer-events: all;
}

.map__iframe._hide-setting {
  left: -20vw;
  right: -20vw;
  bottom: -20vh;
  top: -20vh;
  width: 140vw;
  height: 140vh;
}

.map__container {
  width: 70vw;
  height: 80vh;
  position: relative;
  border: 1px solid #000;
  z-index: 30;
  font-size: 14px;
  margin-top: 10vh;
}

.map__container._hide-setting {
  width: 80vw;
  height: 80vh;
}

.map__iframe {
  pointer-events: all;
}

.map__wrap {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.map__save {
  position: fixed;
  left: 20px;
  top: 20px;
  z-index: 20;
}

.map__compas {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.map__compas img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.map__size {
  pointer-events: all;
  position: absolute;
  display: inline;
  background: #fff;
  /* background: #fff; */
  right: 10px;
  bottom: 10px;
  font-weight: bold;
  text-align: right;
  font-style: italic;
  font-size: 18px;
}

.map__info {
  padding: 10px;
  /* background: #fff; */
  width: 50%;
  max-width: 700px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.map__object {
  position: absolute;
  /* left: 50%;
  top: 50%; */
  width: 100px;
  height: 60px;
  border: 3px solid red;
  /* margin: -10px 0 0 -20px; */
}

.map__object._blue {
  border-color: blue;
  margin-top: 46px;
}

.map__info-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.map__object {
  pointer-events: all;
}

.map__info-item-content {
  display: flex;
  flex-direction: column;
}

.map__info-item-symbol {
  width: 40px;
  height: 20px;
  border: 3px solid red;
  background: #fff;
}

.map__info-item-symbol._blue {
  border-color: blue;
}

.map__info-item-title {
  font-weight: bold;
  display: inline;
  background: #fff;
  font-size: 20px;
  /* text-shadow: 0px 3px 3px #fff,-1px -2px 3px #fff; */
}

.map__info-item-text {
  display: inline;
  background: #fff;
  /* text-shadow: 0px 3px 3px #fff,-1px -2px 3px #fff; */
  font-style: italic;
  font-size: 20px;
}

.map__buttons {
  position: absolute;
  right: 0;
  top: -100px;
  pointer-events: all;
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.map__buttons > div {
  display: flex;
  gap: 8px;
}

.map__image {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  border: none;
  outline: none;
  z-index: 2;
}

.map__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.map__polilinii {
  pointer-events: all;
}